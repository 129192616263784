import React, { useRef, useEffect, useCallback } from "react";
export const TradingViewCard = () => {
    const containerRef = useRef(null);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.tradingview-widget.com/widget/broker-rating.js?locale=en&broker=thinkmarkets";
        script.async = true;
        script.type = "text/javascript";
        if (containerRef.current) {
            containerRef.current.appendChild(script);
        }
    }, []);
    const handleTVWidget = useCallback(() => (`<div class="broker-rating-container">
            <tv-broker-rating class="broker-rating-content" broker="thinkmarkets" lang="en" theme="light"></tv-broker-rating>
        </div>`), []);
    return (React.createElement("div", { ref: containerRef, dangerouslySetInnerHTML: {
            __html: handleTVWidget(),
        } }));
};
