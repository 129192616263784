import React from "react";
import { ImgV2 } from "../../ImgV2";
export const BigOrSmallCard = ({ cardTitle, cardSubTitle, cardImageUrl, cardIconUrl, cardImageOrIconAlt, cardSize }) => (React.createElement("div", { className: "big-or-small-card" },
    React.createElement("div", { className: cardSize === "Big card" ? "big-or-small-card__big-card" : "big-or-small-card__small-card" },
        cardSize === "Small card" && React.createElement("div", { className: "big-or-small-card__icon" },
            React.createElement(ImgV2, { src: cardIconUrl, alt: cardImageOrIconAlt })),
        React.createElement("div", { className: "big-or-small-card__text" },
            React.createElement("h5", { dangerouslySetInnerHTML: {
                    __html: cardTitle || "",
                } }),
            React.createElement("p", { dangerouslySetInnerHTML: {
                    __html: cardSubTitle || "",
                } })),
        cardSize === "Big card" && React.createElement("div", { className: "big-or-small-card__big-image" },
            React.createElement(ImgV2, { src: cardImageUrl, alt: cardImageOrIconAlt })))));
