import React, { useCallback } from "react";
import { Container, ContainerFW } from "../global";
import { ButtonV2 } from "../ButtonV2";
import { TradingViewCard } from "./TradingView";
import { BlackSectionItems } from "./BlackCard";
import { CommonCard } from "./CommonCards";
export const OtherAccounts = ({ mainSubTitle, mainTitle, sectionItems, blackCard, buttonUrl, buttonLabel, tradingViewCard }) => {
    const handleVisibleButton = useCallback(() => {
        if (buttonUrl && buttonLabel) {
            return React.createElement("div", { className: "otherAccountWrapper__buttonWrapper" },
                React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonUrl }, buttonLabel));
        }
        return React.createElement(React.Fragment, null);
    }, [buttonLabel, buttonUrl]);
    const mainTitleSection = () => (mainTitle && React.createElement("h2", { className: "otherAccountWrapper__mainTitle" }, mainTitle));
    const mainSubTitleSection = () => (mainSubTitle && React.createElement("h5", { className: "otherAccountWrapper__mainSubTitle" }, mainSubTitle));
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "otherAccountWrapper-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: `${blackCard?.title ? "otherAccountWrapperWithBlackCard" : "otherAccountWrapper"}` },
                    mainTitleSection(),
                    mainSubTitleSection(),
                    React.createElement("div", { className: "otherAccountWrapper__sectionWrapper" },
                        sectionItems.length > 0 && sectionItems.map((a) => (React.createElement(CommonCard, { key: `unique${a.title}`, blackCardButtonLabel: blackCard?.title, ...a }))),
                        blackCard?.title && React.createElement(BlackSectionItems, { ...blackCard }),
                        tradingViewCard && React.createElement(TradingViewCard, null)),
                    handleVisibleButton())))));
};
