import React, { useState, useEffect } from "react";
import { Img } from "../Img";
import { Container, ContainerFW } from "../global";
import { usePageViewStyle } from "../../hooks";
import { Heading } from "../Heading";
export const RichTextWithImage = (props) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [isSmallValue, setIsSmallValue] = useState(false);
    const [isMediumValue, setIsMediumValue] = useState(false);
    const [isLargeValue, setIsLargeValue] = useState(false);
    useEffect(() => {
        setIsSmallValue(isSmall);
        setIsMediumValue(isMedium);
        setIsLargeValue(isLarge);
    }, [isSmall, isMedium, isLarge]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: `rich-text-with-image ${props.position}` },
                React.createElement("div", { className: "rich-text-with-image__text-container" },
                    props.title && React.createElement(Heading, { design: props.h2title ? "h2-regular" : "h1-regular", style: { "--text-alignment": `${props.textAlignment}` } }, props.title),
                    React.createElement("div", { className: "rich-text-with-image__text", dangerouslySetInnerHTML: {
                            __html: props.richText,
                        }, style: { "--text-alignment": `${props.textAlignment}` } })),
                React.createElement("div", { className: "rich-text-with-image__image-container" },
                    (isLargeValue || isMediumValue) && React.createElement(Img, { className: "rich-text-with-image__image", src: props.imageURL, alt: props.imageAlt }),
                    isSmallValue && React.createElement(Img, { className: "rich-text-with-image__image", src: props.mobileImageURL, alt: props.imageAlt }))))));
};
