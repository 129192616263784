import React from "react";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
import { AwardCard } from "./AwardsCard/AwardCard";
export const AwardsBanner = ({ title, subTitle, awardYear, awardImageUrl, leavesImageUrl }) => (React.createElement(ContainerFW, null,
    React.createElement(Container, null,
        React.createElement("div", { className: "awards-banner-root" },
            React.createElement("div", { className: "awards-banner-root__textsWrapper" },
                React.createElement(Heading, { design: "h3-bold" }, title),
                React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: subTitle,
                    } }),
                React.createElement(Heading, { design: "h5-semi-bold" }, awardYear)),
            React.createElement(AwardCard, { awardImageUrl: awardImageUrl, leavesImageUrl: leavesImageUrl })))));
