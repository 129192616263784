import React from "react";
import { Heading } from "../../../Heading";
import { ImgV2 } from "../../../ImgV2";
export const CardWithImageAndLabel = ({ title, subTitle, imageUrl }) => (React.createElement("div", { className: "card-image-label__root" },
    React.createElement(Heading, { design: "h3-semi-bold-small" }, title),
    React.createElement("p", { className: "caption", dangerouslySetInnerHTML: {
            __html: subTitle
        } }),
    React.createElement("div", { className: "imageWrapper" },
        React.createElement(ImgV2, { src: imageUrl }))));
