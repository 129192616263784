import React, { useMemo } from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { getColors } from "../../utils";
import { Breadcrumbs } from "../Breadcrumbs";
import { Heading } from "../Heading";
export const CentredTitleWithSubtitle = ({ title, subtitle, imageSmall, imageLarge, alt, videoSmall, videoLarge, backgroundColor, titlePosition = "center", subtitlePosition = "center", breadcrumbs, breadcrumbBottomMargin = "30px", buttonHref, buttonLabel, h2title }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const mainCssClasses = "centred-title-with-subtitle__main-container"
        + `${titlePosition !== "center" ? ` centred-title-with-subtitle__main-container--title-${titlePosition}` : ""}`
        + `${subtitlePosition !== "center" ? ` centred-title-with-subtitle__main-container--subtitle-${subtitlePosition}` : ""}`;
    const imgLarge = useMemo(() => (videoLarge ?
        React.createElement("video", { className: "centred-title-with-subtitle__video", autoPlay: true, loop: true, muted: true, playsInline: true },
            React.createElement("source", { src: videoLarge || videoSmall, type: "video/mp4" }),
            React.createElement(Img, { className: "centred-title-with-subtitle__image", src: imageLarge || imageSmall, alt: alt })) :
        React.createElement(Img, { className: "centred-title-with-subtitle__image", src: imageLarge || imageSmall, alt: alt })), [alt, imageLarge, imageSmall, videoLarge, videoSmall]);
    const imgSmall = useMemo(() => (videoSmall ?
        React.createElement("video", { className: "centred-title-with-subtitle__video", autoPlay: true, loop: true, muted: true, playsInline: true },
            React.createElement("source", { src: videoSmall || videoLarge, type: "video/mp4" }),
            React.createElement(Img, { className: "centred-title-with-subtitle__image", src: imageSmall || imageLarge, alt: alt })) :
        React.createElement(Img, { className: "centred-title-with-subtitle__image", src: imageSmall || imageLarge, alt: alt })), [alt, imageLarge, imageSmall, videoLarge, videoSmall]);
    const getTitleStyle = () => h2title ? "h2-regular" : "h1-regular";
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            breadcrumbs && React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs, lightMode: backgroundColor === "white" }),
            React.createElement("div", { style: { color: textColor, marginTop: breadcrumbBottomMargin }, className: "centred-title-with-subtitle" },
                React.createElement("div", { className: mainCssClasses },
                    React.createElement("div", { className: "centred-title-with-subtitle__title" },
                        React.createElement(Heading, { design: getTitleStyle() }, title)),
                    React.createElement("div", { className: "centred-title-with-subtitle__subtitle" },
                        React.createElement("div", { className: "h5-semi-bold centred-title-with-subtitle__description", dangerouslySetInnerHTML: { __html: subtitle } })),
                    imgLarge &&
                        React.createElement("div", { className: "centred-title-with-subtitle__images" },
                            React.createElement("div", { className: "centred-title-with-subtitle__image-container hide-small" }, imgLarge),
                            React.createElement("div", { className: "centred-title-with-subtitle__image-container hide-medium hide-large" }, imgSmall))),
                buttonLabel && React.createElement("div", { className: "centred-title-with-subtitle__button-wrapper" },
                    React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonHref }, buttonLabel))))));
};
