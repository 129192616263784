import React from "react";
import { getColors } from "../../../utils";
import { Container, ContainerFW } from "../../../header";
import { FeaturesList } from "./FeaturesList/FeaturesList";
import { TrustPilotMiniBanner } from "./TrustPilotMiniBanner/TrustPilotMiniBanner";
import { CheckOutButton } from "./CheckOutButton/CheckOutButton";
export const StatsLineRibbonBanner = ({ features, backgroundColor, trustPilotValues, CheckOutButtonValues }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "stats-line-ribbon-banner__root" },
                React.createElement("div", { className: "stats-line-ribbon-banner__root__features" }, features.length > 0 && features.map((a) => (React.createElement(FeaturesList, { key: `unique${a.featureTitle}`, ...a })))),
                React.createElement("div", { className: "stats-line-ribbon-banner__root__bottom-section" },
                    React.createElement(TrustPilotMiniBanner, { ...trustPilotValues }),
                    React.createElement(CheckOutButton, { ...CheckOutButtonValues }))))));
};
