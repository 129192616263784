import React from "react";
import { Container, ContainerFW } from "../../global";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils/getColors";
export const MarketsBanner = ({ title, subTitle, animationVideo, buttonUrl, buttonLabel, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "markets-banner" },
                React.createElement("div", { className: "markets-banner__text-wrapper" },
                    React.createElement("h1", null, title),
                    React.createElement("h5", { dangerouslySetInnerHTML: { __html: subTitle } })),
                React.createElement("div", { className: "markets-banner__button-wrapper" },
                    React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonUrl }, buttonLabel)),
                React.createElement("div", { className: "markets-banner__video-wrapper" },
                    React.createElement("video", { width: "100%", loop: true, autoPlay: true, muted: true, playsInline: true },
                        React.createElement("source", { src: animationVideo, type: "video/mp4" })))))));
};
