import * as React from "react";
import { ContainerFW, Container } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { SmallAwardCard } from "../Cards/SmallAwardCardsDeck/SmallAwardCard/SmallAwardCard";
export const AwardsSlider = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "awards" },
        props.title && React.createElement("h2", { className: "awards__title" }, props.title),
        React.createElement(Container, null,
            React.createElement("div", { className: "awards__wrapper" },
                React.createElement(Swiper, { modules: [Navigation, Pagination], spaceBetween: 50, slidesPerView: 1, navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }, breakpoints: {
                        375: {
                            slidesPerView: 1.5,
                        },
                        601: {
                            slidesPerView: 2.6,
                        },
                        1024: {
                            slidesPerView: props.awards.length,
                        },
                    }, pagination: {
                        el: ".awards__pagination",
                        clickable: true,
                        renderBullet: () => "<span class='awards__bullet swiper-pagination-bullet'></span>",
                    } }, props.awards.map((award) => (React.createElement(SwiperSlide, { key: `awards-slide-${award.title}` },
                    React.createElement(SmallAwardCard, { ...award })))))),
            React.createElement("div", { className: "awards__pagination" })))));
