import React from "react";
export const BigAwardsCard = ({ awardImgUrl, title, subTitle, year, organization, design, backgroundImg, }) => {
    const imageUrlCss = backgroundImg ? `url(${backgroundImg})` : "none";
    return (React.createElement("section", { className: `big-awards-card${design ? ` big-awards-card--${design}` : ""}`, style: {
            "--background-image-url": imageUrlCss,
        } },
        React.createElement("img", { src: awardImgUrl, alt: organization }),
        React.createElement("h3", null, title),
        React.createElement("div", null,
            React.createElement("span", { className: "big-awards-card__subtitle" }, subTitle),
            React.createElement("span", { className: "big-awards-card__year" }, year))));
};
