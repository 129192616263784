import React from "react";
import { ImgV2 } from "../../ImgV2";
import { Container, ContainerFW } from "../../global";
import { StatsList } from "./StatsList/StatsList";
import { Heading } from "../../Heading";
import { getColors } from "../../../utils";
export const HeaderWithImgAndStatsBanner = ({ imageUrl, title, subtitle, stats, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "header-with-img-stats-banner-root" },
                React.createElement("div", { className: "header-with-img-stats-banner-root__image-wrapper" },
                    React.createElement(ImgV2, { src: imageUrl })),
                React.createElement("div", { className: "header-with-img-stats-banner-root__texts-wrapper" },
                    React.createElement(Heading, { design: "h1-regular" }, title),
                    React.createElement(Heading, { design: "h5-semi-bold" }, subtitle),
                    React.createElement("div", { className: "stats-wrapper" }, stats.length > 0 && stats.map((stat) => (React.createElement(StatsList, { key: `unique${stat.description}`, ...stat })))))))));
};
