import React from "react";
import { ButtonSquare } from "../../ButtonSquare";
import { Img } from "../../Img";
export const CommonCard = ({ buttonLabel, buttonLink, imageAlt, imageUrl, subTitle, title, cardBackground, blackCardButtonLabel }) => (React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section", style: { backgroundColor: cardBackground || "#F2F6F6" }, key: `unique${title}` },
    React.createElement(Img, { className: "otherAccountWrapper__sectionWrapper__section__image", src: imageUrl, alt: imageAlt }),
    React.createElement("h5", { className: "otherAccountWrapper__sectionWrapper__section__title" }, title),
    React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section__subtitle", dangerouslySetInnerHTML: {
            __html: subTitle,
        } }),
    (buttonLabel && !blackCardButtonLabel) && React.createElement(ButtonSquare, { label: buttonLabel, href: buttonLink })));
