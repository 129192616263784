import React from "react";
import { Container, ContainerFW } from "../global";
import { BigOrSmallCard } from "./BigOrSmallCard/BigOrSmallCard";
import { getColors } from "../../utils/getColors";
export const GridBigAndSmallCards = ({ title, subTitle, bigCardPosition, cards, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "grid-big-and-small-cards" },
                (title || subTitle) && React.createElement("div", { className: "grid-big-and-small-cards__text-wrapper" },
                    React.createElement("h2", { dangerouslySetInnerHTML: {
                            __html: title || "",
                        } }),
                    React.createElement("p", { className: "grid-big-and-small-cards__subtitle", dangerouslySetInnerHTML: {
                            __html: subTitle || "",
                        } })),
                React.createElement("div", { className: "grid-big-and-small-cards__cards-wrapper", style: { flexDirection: bigCardPosition === "Left" ? "row" : "row-reverse" } },
                    React.createElement("div", { className: "grid-big-and-small-cards__big-cards-wrapper" }, cards.map((card, i) => {
                        if (i < 1) {
                            return (React.createElement(BigOrSmallCard, { key: `${i}`, cardTitle: card.cardTitle, cardSubTitle: card.cardSubTitle, cardImageUrl: card.cardImageUrl, cardImageOrIconAlt: card.cardImageOrIconAlt, cardBackgroundColor: card.cardBackgroundColor, cardSize: card.cardSize }));
                        }
                        else {
                            return (React.createElement(React.Fragment, null));
                        }
                    })),
                    React.createElement("div", { className: "grid-big-and-small-cards__small-cards-wrapper" }, cards.map((card, i) => {
                        if (i > 0) {
                            return (React.createElement(BigOrSmallCard, { key: `${i}`, cardTitle: card.cardTitle, cardSubTitle: card.cardSubTitle, cardIconUrl: card.cardIconUrl, cardImageOrIconAlt: card.cardImageOrIconAlt, cardBackgroundColor: card.cardBackgroundColor, cardSize: card.cardSize }));
                        }
                        else {
                            return (React.createElement(React.Fragment, null));
                        }
                    })))))));
};
