import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Heading } from "../Heading";
import { Img } from "../Img";
export const AnimatedCard = ({ buttonHref, buttonLabel, imgUrlDesktop, imgUrlTablet, imgUrlPhone, cardContent, title }) => (React.createElement("div", { className: "animated-card-root" },
    React.createElement("div", { className: "animated-card-root__imageWrapper" },
        React.createElement(Img, { src: imgUrlDesktop })),
    React.createElement("div", { className: "animated-card-root__textWrapper" },
        React.createElement(Heading, { design: "h4-bold" }, title),
        React.createElement("p", { dangerouslySetInnerHTML: {
                __html: cardContent
            } })),
    React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonHref }, buttonLabel)));
