import React from "react";
import { ImgV2 } from "../../../ImgV2";
export const CardWithOutHoverAnimation = ({ title, text, iconUrl, iconAlt, bgColor, textColor, descriptionTextColor }) => (React.createElement("div", { className: "card-withOut-hover", style: { "--background-image-url": bgColor } },
    React.createElement("div", { className: "card-withOut-hover__imageWrapper" },
        React.createElement(ImgV2, { src: iconUrl, alt: iconAlt })),
    React.createElement("p", { className: "card-withOut-hover__title", style: { "--text-color": textColor }, dangerouslySetInnerHTML: {
            __html: title || "",
        } }),
    React.createElement("div", { className: "card-withOut-hover__description", dangerouslySetInnerHTML: {
            __html: text || "",
        }, style: { "--description-text-color": descriptionTextColor } })));
