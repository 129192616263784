import React from "react";
import { Heading } from "../../Heading";
import { Container, ContainerFW } from "../../../header";
import { ButtonV2 } from "../../ButtonV2";
export const MarketCard = ({ categoryName, linkLabel, linkUrl, subtitle, title, mobileBgImage, desktopBgImage, openInNewTabButton = false }) => (React.createElement(ContainerFW, null,
    React.createElement(Container, null,
        React.createElement("div", { className: "market-card-root", style: { "--background-image-url-mobile": `url(${mobileBgImage})`, "--background-image-url-desktop": `url(${desktopBgImage})` } },
            React.createElement("p", { className: "caption-risk-warning-bold" }, categoryName),
            React.createElement("div", { className: "market-card-root__title-wrapper" },
                React.createElement(Heading, { design: "h4-semi-bold" }, title),
                React.createElement("p", { className: "market-card-root__title-wrapper__subtitle" }, subtitle)),
            React.createElement(ButtonV2, { classes: "market-card-root__button", openInNewTab: openInNewTabButton, href: linkUrl }, linkLabel)))));
