import React from "react";
import { Container, ContainerFW } from "../../../header";
import { usePageViewStyle } from "../../../hooks";
import { MobileSmallAwardCard } from "./MobileSmallAwardCard/MobileSmallAwardCard";
import { SmallAwardCard } from "./SmallAwardCard/SmallAwardCard";
export const SmallAwardCardsDeck = ({ awardCards, title }) => {
    const { isSmall } = usePageViewStyle();
    return (React.createElement(Container, null,
        React.createElement("div", { className: "small-award-cards-deck-root" },
            React.createElement(ContainerFW, null,
                React.createElement("div", { className: "small-award-cards-deck-root__title", dangerouslySetInnerHTML: {
                        __html: title,
                    } }),
                awardCards.length > 0 && awardCards.map((award) => (React.createElement(React.Fragment, null, isSmall ? React.createElement(MobileSmallAwardCard, { key: award.title, title: award.title, subtitle: award.subtitle, mobileAwardImage: award.mobileAwardImage, date: award.date }) : React.createElement(SmallAwardCard, { key: award.title, date: award.date, title: award.title, subtitle: award.subtitle, leftLeavesImage: award.leftLeavesImage, rightLeavesImage: award.rightLeavesImage }))))))));
};
