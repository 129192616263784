import React, { Fragment, useCallback } from "react";
import { Heading } from "../../Heading";
import { ImgV2 } from "../../ImgV2";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { usePageViewStyle } from "../../../hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
SwiperCore.use([Navigation, Autoplay, Pagination]);
export const CardsDeckWithSliderOnMobile = ({ title, cards, cardsBackgroundColor }) => {
    const { bgColor } = getColors(cardsBackgroundColor);
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const renderCard = useCallback((card) => (React.createElement("div", { style: { backgroundColor: bgColor }, className: "cards-deck-with-slider-on-mobile__card" },
        React.createElement("div", null,
            React.createElement(ImgV2, { src: card.iconUrl })),
        React.createElement("div", null,
            React.createElement(Heading, { design: "h5-bold" }, card.title)),
        React.createElement("div", { className: "p-regular", dangerouslySetInnerHTML: {
                __html: card.description
            } }))), [bgColor]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "cards-deck-with-slider-on-mobile" },
                React.createElement(Heading, { design: "h3-semi-bold" }, title),
                (isLarge || isMedium) && React.createElement("div", { className: "cards-deck-with-slider-on-mobile__main-grid" }, cards?.map((card, i) => React.createElement(Fragment, { key: i }, renderCard(card)))),
                isSmall &&
                    React.createElement(Swiper, { spaceBetween: 20, slidesPerView: 1.2, pagination: {
                            clickable: true,
                        } }, cards?.map((card, i) => React.createElement(SwiperSlide, { key: `cards-deck-with-slider-on-mobile__slide-${i}` }, renderCard(card))))))));
};
